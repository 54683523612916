import { theme } from '@/styles/theme.css'
import { forwardRef, type CSSProperties, type ReactNode } from 'react'
import * as styles from './Text.css'

export interface FontProps {
  weight?: CSSProperties['fontWeight']
  size?: CSSProperties['fontSize']
  family?: CSSProperties['fontFamily']
  color?: 'opaque' | 'primary' | 'secondary' | 'tertiary' | CSSProperties['color']
  fontStyle?: CSSProperties['fontStyle']
}

export interface TextProps extends Omit<React.HTMLAttributes<HTMLElement>, 'color'>, FontProps {
  hyphens?: boolean
  className?: string
  style?: CSSProperties
  maxLines?: number
  balance?: boolean
  align?: CSSProperties['textAlign']
  as?: keyof HTMLElementTagNameMap
  children?: ReactNode
}

/**
 * Renders text
 *
 * TODO:
 * - Add `TypoProvider` (makes it possible to set prop-default accross a whole tree)
 */
const Text = forwardRef<HTMLElement, TextProps>(function Text ({
  children,
  hyphens = false,
  className = '',
  family,
  size,
  weight,
  fontStyle,
  maxLines,
  color = 'primary',
  style = {},
  as: Tag = 'p',
  balance,
  align,
  ...props
}, ref) {
  let _color
  if (color === 'opaque' || color === 'secondary' || color === 'tertiary' || color === 'brand') {
    _color = theme.colors.text[color]
  } else if (color !== 'primary') {
    _color = color
  }

  return (
    <Tag
      // @ts-expect-error see comment above
      {...props}
      ref={ref}
      style={{
        ...style,
        fontWeight: weight,
        fontSize: size,
        color: _color,
        fontFamily: family,
        fontStyle,
        // @ts-expect-error types need updating
        textWrap: balance ? 'balance' : undefined,
        textAlign: align,
        '--max-lines': maxLines,
      }}
      className={`${hyphens ? styles.hyphens : ''} ${className}`}
    >
      {children}
    </Tag>
  )
})

export default Text
