import { useStore } from '@/lib/store/store'
import { scrollbarWidth } from '@xobotyi/scrollbar-width'
import { writable } from 'svelte/store'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'

scrollbarWidth()

const scrollbarOffsetStore = writable(0)
export function useScrollbarOffset (): number {
  return useStore(scrollbarOffsetStore)
}

export default function useScrollLock (enabled: boolean = true): void {
  useIsomorphicLayoutEffect(() => {
    if (enabled) {
      const supportsScrollbarGutter = CSS.supports('scrollbar-gutter', 'stable')
      const scrollbarOffset = scrollbarWidth() ?? 0

      document.documentElement.style.setProperty('overflow', 'hidden')
      if (!supportsScrollbarGutter) {
        document.documentElement.style.setProperty('margin-right', `${scrollbarOffset}px`)
      }
      // Even when scrollbar-gutter is supported set this value, because of elements with `position: fixed`
      scrollbarOffsetStore.set(scrollbarOffset)

      return () => {
        document.documentElement.style.removeProperty('overflow')
        if (!supportsScrollbarGutter) {
          document.documentElement.style.removeProperty('margin-right')
        }
        scrollbarOffsetStore.set(0)
      }
    }
  }, [enabled])
}
