import { useEffect, useState } from 'react'

/**
 * Useful for some cases where rendering on the server (Server-side Rendering, SSR) can cause issues with hydration.
 * Use this function to skip the initial React render.
 *
 * @returns A boolean which is true on the first render, and false on subsequent renders
 */
const useFirstRender = function (): boolean {
  const [render, setRender] = useState(true)
  useEffect(() => {
    setRender(false)
  }, [])
  return render
}
export default useFirstRender
