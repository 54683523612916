'use client'

// import useScrollLock, { useScrollbarOffset } from '@/hooks/useScrollLock'
import { useScrollbarOffset } from '@/hooks/useScrollLock'
import { Row, Stack } from '@christiankaindl/lyts'
import { X } from 'lucide-react'
import { forwardRef, useRef, type ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'
import Button from '../Button/Button'
import Text from '../Text/Text'
import * as styles from './Overlay.css'
import { Dialog, DialogDescription, DialogDismiss, DialogHeading, type DialogProps, DialogProvider, type DialogProviderProps } from '@ariakit/react'

export function Overlay ({ children, ...props }: DialogProviderProps): JSX.Element {
  // useScrollLock(open)

  return (
    <DialogProvider {...props}>
      {children}
    </DialogProvider>
  )
}

interface OverlayContentProps extends DialogProps {
  title: string
  description?: string
  header?: boolean
  children: ReactNode
}
export const OverlayContent = forwardRef<HTMLDivElement, OverlayContentProps>(function OverlayContent ({ title, description, children, header = true, ...props }: OverlayContentProps, ref): JSX.Element {
  const dialogRef = useRef<null | HTMLDivElement>(null)
  const { ref: titleRef, inView: titleInView } = useInView({
    threshold: 0.5,
    initialInView: false,
    root: dialogRef?.current,
  })

  const headerContent = header
    ? (
      <>
        <StickyHeader text={title} subText={description} show={!titleInView} />
        <Row xAlign='space-between' yAlign='flex-start'>
          <Stack gap={0.25}>
            <DialogHeading
              className={styles.title}
              ref={titleRef}
            >
              {title}
            </DialogHeading>
            {description && (
              <DialogDescription
                render={(
                  <Text color='secondary'>
                    {description}
                  </Text>
                )}
              />
            )}
          </Stack>
        </Row>
      </>)
    : null

  return (
    <Dialog
      ref={dialogRef}
      className={styles.content}
      style={{
        zIndex: 1000,
        right: useScrollbarOffset(),
      }}
      backdrop={<div className={styles.backdrop} />}
      {...props}
    >
      <Stack className={styles.contentInner} ref={ref}>
        {headerContent}
        {children}
      </Stack>
    </Dialog>
  )
})

function StickyHeader ({ text, subText, show }: { text: string, subText?: string, show: boolean }) {
  return (
    <div className={styles.stickyHeader}>
      <Stack gap={0} className={styles.bg} xAlign='center' yAlign='center' style={{ opacity: show ? 1 : 0 }}>
        <Text as='span' weight={650}>{text}</Text>
        {subText && (
          <Text size='small' color='secondary' maxLines={1}>{subText}</Text>
        )}
      </Stack>
      <DialogDismiss render={<Button circular square size='small' style={{ position: 'absolute', right: 12, top: 13 }} />}>
        <X size={16} />
      </DialogDismiss>
    </div>
  )
}
