import { useMemo, useRef } from 'react'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'

type Fn<ARGS extends any[], R> = (...args: ARGS) => R

/**
 * Stable function references without dependecy arrays
 * @returns A stable fuction
 * @see https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 * @from https://github.com/Volune/use-event-callback
 */
const useEvent = <A extends any[], R>(fn: Fn<A, R>): Fn<A, R> => {
  const ref = useRef<Fn<A, R>>(fn)
  useIsomorphicLayoutEffect(() => {
    ref.current = fn
  })
  return useMemo(() => (...args: A): R => {
    const { current } = ref
    return current(...args)
  }, [])
}

export default useEvent
