'use client'

import * as styles from './Search.css'
import { type CSSProperties, forwardRef, memo, useRef, useState, useEffect, useTransition } from 'react'
import { Search as SearchIcon } from 'lucide-react'
import { Row } from '@christiankaindl/lyts'
import Link from 'next/link'
import useKeyPress from '@/hooks/useKeyPress'
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip/Tooltip'
import {
  Combobox,
  ComboboxProvider,
  useComboboxStore,
} from '@ariakit/react'
import { useRouter, useSearchParams } from 'next/navigation'
import Button from '../Button/Button'
import dynamic from 'next/dynamic'
import useFirstRender from '@/hooks/useFirstRender'
import Text from '@/components/Text/Text'
import Spinner from '../Spinner/Spinner'

const SearchPopover = dynamic(async () => await import('./SearchPopover'))

interface SearchProps {
  center?: boolean
  style?: CSSProperties
  placeholder?: string
  onSelect?: () => void
  id?: string
  autoFocus?: boolean
  enableShortcut?: boolean
  inputProps?: React.HTMLProps<HTMLInputElement>
  className?: string
}

let modifierKeyPrefix = 'Strg' // control key
if (typeof navigator !== 'undefined') {
  if (navigator?.platform?.indexOf('Mac') === 0 || navigator?.platform === 'iPhone') {
    modifierKeyPrefix = '⌘' // command key
  }
}

const Search = memo(function Search ({ style = {}, inputProps, placeholder, className, id, autoFocus = false, enableShortcut = true }: SearchProps) {
  const router = useRouter()
  const params = useSearchParams()
  const q = params?.get('q')

  const [isPending, startTransition] = useTransition()

  const combobox = useComboboxStore({
    placement: 'bottom',
    defaultValue: q ?? '',
  })
  const value = combobox.useState('value')
  const activeId = combobox.useState('activeId')
  const [isFocused, setIsFocused] = useState(autoFocus)
  const ref = useRef<HTMLInputElement>(null)

  const isHome = id === 'home-search'
  if (isHome && typeof window !== 'undefined') {
    // @ts-expect-error Used to trigger the search on the homepage
    window._workaroundOpenSearch = () => {
      ref?.current?.focus()
      combobox.setOpen(true)
    }
  }

  function focusSearchInput (event: KeyboardEvent | PointerEvent) {
    event.preventDefault()

    ref?.current?.focus()
    combobox.setOpen(true)
  }

  useKeyPress({
    targetKey: 'k',
    onKeyDown (event) {
      if (!event.metaKey && !event.ctrlKey) return

      focusSearchInput(event)
    },
    disabled: !enableShortcut,
  })

  function submitSearch () {
    if (!value) return

    startTransition(() => {
      router.push(`/suche?q=${value}`)
      combobox.setOpen(false)
    })
  }

  useEffect(function syncUrlToInput () {
    if (isHome) return

    combobox.setValue(params?.get('q') ?? '')
    combobox.setOpen(false)
  }, [isHome, params?.get('q')])

  return (
    <ComboboxProvider store={combobox}>
      <form
        action={submitSearch}
        style={{ position: 'relative', ...style }}
        className={className}
      >
        <Combobox
          render={<Input isPending={isPending} rootProps={{ id: isHome ? 'home-search-root' : undefined }} inputProps={inputProps} />}
          onFocus={() => { setIsFocused(true) }}
          onBlur={() => { setIsFocused(false) }}
          id={id}
          name='query'
          autoFocus={autoFocus}
          ref={ref}
          placeholder={placeholder}
        />
        {isHome && (
          <Button
            onPointerDown={submitSearch}
            size='small'
            style={{
              position: 'absolute',
              right: 6,
              top: 6,
              height: 33,
              padding: '0 12px',
              backgroundColor: 'rgb(234, 212, 176, 0.44)',
              color: '#956A25',
            }}
          >
            Suchen
          </Button>
        )}
        {!isHome && isFocused && (value || activeId) && (
          <Button size='tiny' onPointerDown={submitSearch} className={styles.keyboardHint}>Suchen</Button>
        )}
        {!isHome && !isFocused && (
          <KeyboardHint focusInput={focusSearchInput} />
        )}
      </form>
      <SearchPopover query={value} />
    </ComboboxProvider>
  )
})

Search.displayName = 'Search'
export default Search

const KeyboardHint = forwardRef<HTMLButtonElement, { focusInput: (event: any) => void }>(function KeyboardHint ({ focusInput, ...props }, ref) {
  const isFirstRender = useFirstRender()

  return (
    <Tooltip>
      <TooltipTrigger render={<Button size='tiny' ref={ref} onPointerDown={focusInput} className={styles.keyboardHint} />}>
        {!isFirstRender && modifierKeyPrefix}+K
      </TooltipTrigger>
      <TooltipContent>
        Suche fokussieren
      </TooltipContent>
    </Tooltip>
  )
})

type InputProps = React.HTMLProps<HTMLInputElement> & {
  rootProps?: React.HTMLProps<HTMLDivElement>
  inputProps?: React.HTMLProps<HTMLInputElement>
  icon?: boolean
  endWidget?: React.ReactNode
  isPending?: boolean
}
export const Input = forwardRef<HTMLInputElement, InputProps>(function Input ({
  style,
  placeholder = 'Gesetze, Entscheidungen, ...',
  icon = true,
  rootProps = {},
  inputProps = {},
  endWidget,
  isPending = false,
  ..._inputProps
}, ref) {
  return (
    <div
      className={`${styles.root} ${rootProps?.className ?? ''}`}
      style={style}
      {...rootProps}
      aria-disabled={inputProps.disabled}
    >
      {icon && !isPending && <SearchIcon size={20} className={styles.icon} />}
      {icon && isPending && <Spinner size={20} className={styles.icon} />}
      <input {..._inputProps} {...inputProps} ref={ref} className={`${styles.input} ${inputProps.className ?? ''}`} placeholder={placeholder} style={{ paddingLeft: icon ? 47 : 15 }} />
      {endWidget && (
        endWidget
      )}
    </div>
  )
})

interface ResultItemProps {
  hit: any
  query: string
  insights?: () => void
  style?: CSSProperties
  onSelect?: () => void
}
export const ResultItem = forwardRef<HTMLDivElement, ResultItemProps>(function ResultItem ({
  hit,
  style = {},
  onSelect = () => {},
  ...props
}, ref) {
  if (!hit.q) {
    return null
  }

  return (
    <Row gap='12px' asChild style={style} ref={ref} {...props}>
      <Link
        href={`/suche?q=${hit.q as string}`}
        className={`${styles.resultItem} ${hit?._highlightResult?.q.matchLevel !== 'none' ? styles.hasInput : ''}`}
        onClick={() => {
          onSelect?.()
        }}
      >
        <SearchIcon size={16} style={{ opacity: 0.35 }} />
        {hit.q && (
          <Text>{hit.q}</Text>
        )}
      </Link>
    </Row>
  )
})
