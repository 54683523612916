'use client'

import { startTransition, useEffect, useState } from 'react'
import { get, type Readable } from 'svelte/store'

interface Options {
  /**
   * Whether or not to use `startTransition` to update the state
   *
   * Useful for Suspense compatibility.
   * Set to false when dealing with inputs, as otherwise it causes the input cursor position to reset when typing.
   */
  useTransition?: boolean
}

export function useStore<T> (store: Readable<T>, { useTransition = true }: Options = {}): T {
  const [value, setValue] = useState<T>(() => get(store))
  useEffect(() => store.subscribe((val) => {
    if (useTransition) {
      startTransition(() => { setValue(val) })
    } else {
      setValue(val)
    }
  }), [store, useTransition])
  return value
}
