import { useEffect } from 'react'
import useEvent from './useEvent'

interface Params {
  targetKey: string
  onKeyDown?: (event: KeyboardEvent) => void
  onKeyUp?: (event: KeyboardEvent) => void
  disabled?: boolean
}

export default function useKeyPress ({
  targetKey,
  onKeyDown,
  onKeyUp,
  disabled = false,
}: Params) {
  // If pressed key is our target key then set to true
  const onDown = useEvent(function downHandler (event) {
    if (event.key === targetKey) {
      onKeyDown?.(event)
    }
  })

  const onUp = useEvent(function downHandler (event) {
    if (event.key === targetKey) {
      onKeyUp?.(event)
    }
  })

  // Add event listeners
  useEffect(() => {
    if (disabled) return

    window.addEventListener('keydown', onDown)
    window.addEventListener('keyup', onUp)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', onDown)
      window.removeEventListener('keyup', onUp)
    }
  }, [onDown, onUp, disabled])
}
